<template>
  <v-app>
    <mcd-header-component></mcd-header-component>
    <v-main v-if="Object.keys(JSONcontent).length && Object.keys(JSONsuppliers).length">
      <router-view />
      <mcd-footer-component></mcd-footer-component>
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: "App",

  mounted() {
    this.matomoTrackingEventMobileAppStoresClicked();
    // this.adjustHeaderComponentStyling();
  },
  data: () => ({
    JSONcontent: {},
    JSONsuppliers: {},
  }),
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get(process.env.VUE_APP_ASSETS_URL + "lang.json")
        .then((response) => {
          this.$root.JSONcontent = response.data.de;
          this.JSONcontent = response.data.de;
        })
        .catch((error) => {
          console.error(error);
        });

      axios
        .get(process.env.VUE_APP_ASSETS_URL + "suppliers.json")
        .then((response) => {
          this.$root.JSONsuppliers = response.data.suppliers;
          this.$root.JSONhasCampaign = response.data.hasCampaign;
          this.JSONsuppliers = response.data.suppliers;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    matomoTrackingEventMobileAppStoresClicked() {
      setTimeout(() => {
        const superUltraRoot = document.querySelector(
          "mcd-footer-component"
        ).shadowRoot;
        const appleButton = superUltraRoot.querySelector(
          "#buttonAppleAppStore"
        );
        appleButton.addEventListener("click", () => {
          window._paq.push([
            "trackEvent",
            "Conversions",
            "App Stores",
            "Apple App Store",
          ]);
          console.log("AppleStore");
        });
      }, 1000);

      setTimeout(() => {
        const superUltraRoot = document.querySelector(
          "mcd-footer-component"
        ).shadowRoot;
        const googleButton = superUltraRoot.querySelector(
          "#buttonGooglePlayStore"
        );
        googleButton.addEventListener("click", () => {
          console.log("googlePlay");
          window._paq.push([
            "trackEvent",
            "Conversions",
            "App Stores",
            "Google Play",
          ]);
        });
      }, 1000);
    },
    adjustHeaderComponentStyling() {
      setTimeout(() => {
        document
          .querySelector("mcd-header-component")?.shadowRoot.querySelector(".desktop-nav")?.setAttribute("style", "box-shadow:unset;");
      }, 10);
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: 1168px;
}

@for $i from 0 through 16 {
  .row-gap-#{$i},
  .gap-#{$i} {
    row-gap: $i * 4px;
  }
  .col-gap-#{$i},
  .gap-#{$i} {
    column-gap: $i * 4px;
  }
  @media (min-width: 600px) {
    .row-gap-xs-#{$i},
    .gap-xs-#{$i} {
      row-gap: $i * 4px;
    }
    .col-gap-xs-#{$i},
    .gap-xs-#{$i} {
      column-gap: $i * 4px;
    }
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

@media (min-width: 960px) {
  .v-application .main-container .mb-md-25 {
    margin-bottom: 100px !important;
  }
  .v-application .main-container .mb-md-20 {
    margin-bottom: 80px !important;
  }
}

.w-100 {
  width: 100%;
}
</style>
